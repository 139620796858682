<template>
    <div class="table-row">
        <v-row no-gutters align-md="center">
            <v-col cols="12" md="auto" class="mb-n18 mb-md-0 min-w-md-180px">
                <div class="pa-12 py-md-16 px-md-20">
                    <p class="page-text font-weight-medium">
                        {{title}} <span v-if="pointer" style="color: #E91E63;">*</span>
                    </p>
                </div>
            </v-col>
            <v-col cols="12" md="">
                <div class="page-text page-text--sm grey--text pa-12 py-md-16 px-md-20">
                    <slot />
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default{
    props : {
        title : {type :String, default : ""},
        pointer: { type: Boolean, default: false },
    },
	components: {
	},
}
</script>
<style lang="scss" scoped>
.table-row{
    border-bottom: 1px solid var(--v-grey-lighten4);
}
small{
    font-size: 1.2rem;
    line-height: 16px;
    color: var(--v-grey-lighten1);
    padding: 0 16px;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>